import React from 'react'

import Layout from '../components/Layout'
import SEO from "../components/Seo"

export default ({location}) =>
  <Layout className="policiesPage policiesPage--tos">
    <SEO
      title="LicenseSpring: Thank you"
      path={location.pathname}
    />
    <div className="container">
      <h3>Thank you</h3>
      <p>
        We have received your request, you will hear from us shortly.
      </p>
    </div>
  </Layout>